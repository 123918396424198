<template>
    <div class="ui translucent suimodal background-transparent" :class="{ small: !checkMobile, overlay: checkMobile, fullscreen: checkMobile }">
        <i class="close icon" />
        <div class="header">
            {{$t("profile.avatar_image")}}
        </div>
        <div class="content">
            <form class="ui form">
                <div class="grouped fields">
                    <div class="field">
                        <div class="ui right aligned radio checkbox">
                            <input v-model="avatarOption" type="radio" name="avatarOptions" checked="checked" value="metaverse">
                            <label>
                                <i class="material-icons">person</i>
                                {{$t("profile.avatar_metaverse")}}
                            </label>
                        </div>
                    </div>
                    <div class="field">
                        <div class="ui right aligned radio checkbox">
                            <input v-model="avatarOption" type="radio" name="avatarOptions" value="avatar">
                            <label>
                                <i class="material-icons">camera</i>
                                {{$t("profile.avatar_custom")}}
                            </label>
                        </div>
                    </div>
                </div>
            </form>
            <div v-if="avatarOption === 'metaverse'">
                <!-- Exibindo uma imagem fixa para o avatar do metaverso -->
                <div class="ui image">
                    <img :src="userInformation.profile.avatar_metaverse">
                </div>
            </div>
            <div v-if="avatarOption === 'avatar'">
                <input style="display: none" ref="uploadAvatarInput" type="file" accept="image/*" @change="uploadAvatar">
                <div v-if="showAvatar.length>0" class="ui image">
                    <button class="edit-profile" @click="openUploadDialog">
                        <i class="material-icons">edit</i>
                    </button>
                    <img :src="showAvatar">
                </div>
                <div v-else>
                    <div class="field upload-area"
                         :class="{ dragging: isDragging }"
                         ref="upload-area"
                         @dragover.prevent="onDragOver"
                         @dragleave.prevent="onDragLeave"
                         @drop.prevent="onDrop"
                         @click="openUploadDialog">
                        <svg width="92" height="66" viewBox="0 0 92 66" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21.9545 66C15.9606 66 10.803 63.8654 6.48182 59.5962C2.16061 55.3271 0 50.2316 0 44.3099C0 38.939 1.725 34.205 5.175 30.108C8.625 26.011 13.0682 23.5493 18.5045 22.723C19.8985 16.0438 23.1742 10.5869 28.3318 6.35211C33.4894 2.11737 39.4485 0 46.2091 0C54.0848 0 60.6886 2.80595 66.0205 8.41784C71.3523 14.0297 74.0182 20.7606 74.0182 28.6103V31.0892C79.0364 30.9515 83.2879 32.5524 86.7727 35.892C90.2576 39.2316 92 43.4491 92 48.5446C92 53.2958 90.2576 57.3928 86.7727 60.8357C83.2879 64.2786 79.1409 66 74.3318 66H49.1364C47.4636 66 46 65.3803 44.7455 64.1408C43.4909 62.9014 42.8636 61.4554 42.8636 59.8028V33.1549L34.1864 41.7277L29.6909 37.2864L46 21.1737L62.3091 37.2864L57.8136 41.7277L49.1364 33.1549V59.8028H74.3318C77.4682 59.8028 80.1515 58.7011 82.3818 56.4977C84.6121 54.2942 85.7273 51.6432 85.7273 48.5446C85.7273 45.446 84.6121 42.795 82.3818 40.5915C80.1515 38.3881 77.4682 37.2864 74.3318 37.2864H67.7455V28.6103C67.7455 22.482 65.6371 17.2144 61.4205 12.8075C57.2038 8.40063 51.9939 6.19718 45.7909 6.19718C39.5879 6.19718 34.3606 8.40063 30.1091 12.8075C25.8576 17.2144 23.7318 22.482 23.7318 28.6103H21.7455C17.4242 28.6103 13.7652 30.108 10.7682 33.1033C7.77121 36.0986 6.27273 39.7997 6.27273 44.2066C6.27273 48.4757 7.80359 52.1424 10.8653 55.2066C13.927 58.2707 17.6234 59.8028 21.9545 59.8028H36.5909V66H21.9545Z"
                                  fill="var(--buttons_color)"/>
                        </svg>
                        <h3>{{$t('profile.drag_drop')}}</h3>
                        {{$t('profile.or')}} <strong>{{$t('profile.click_here')}}</strong> {{$t('profile.to_search_files')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="actions">
            <div class="ui deny button">
                {{$t('profile.cancelar')}}
            </div>
            <div class="ui positive button">
                {{$t('profile.confirm')}}
            </div>
        </div>
    </div>
</template>
<script>
import {mapGetters} from "vuex";

const emitter = require('tiny-emitter/instance')
export default {
    name: 'ChangeAvatarModal',
    props: {
        userInformation: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            avatarOption: 'metaverse',
            profile_picture: null,
            isDragging: false
        }
    },
    computed: {
        ...mapGetters([
            "checkMobile"
        ]),
        showAvatar(){
            return this.profile_picture?this.profile_picture.src:this.userInformation.profile.custom_profile_photo;
        }
    },
    mounted() {
        this.avatarOption = this.userInformation.profile.use_avatar ? 'metaverse' : 'avatar';
        window.$(this.$el).suimodal({
            closable: false,
            onApprove: () => {
                this.confirmSelection()
                return false;
            },
            onDeny: () => {
                // Implementar a lógica de cancelamento
            },
            onHidden: () => {
                this.$emit('close');
            }
        }).suimodal('show');
        window.$(this.$el).find(".ui.checkbox").checkbox();
    },
    beforeDestroy() {
        window.$(this.$el).remove();
    },
    methods: {
        uploadAvatar(event) {
            const reader = new FileReader()
            reader.readAsDataURL(event.target.files[0])
            reader.onload = (e) => {
                this.profile_picture = event.target.files[0]
                this.profile_picture.src = e.target.result
            }
        },
        confirmSelection() {
            if (this.avatarOption === 'avatar' && !this.showAvatar && this.showAvatar.length === 0) {
                //error toast
                window.$.toast({
                    class: 'error',
                    position: 'top right',
                    title: 'Erro',
                    message: this.$t('profile.select_avatar_error'),
                    showProgress: 'bottom',
                    displayTime: 6000,
                    showIcon: 'times',
                });
                return;
            }
            if (this.profile_picture) {
                delete this.profile_picture.src;
            }
            emitter.emit('avatar::change', this.avatarOption, this.profile_picture);
        },
        close() {
            window.$(this.$el).suimodal('hide');
        },
        onDragOver(){
            this.isDragging = true;
        },
        onDragLeave(){
            this.isDragging = false;
        },
        onDrop(event){
            this.isDragging = false;
            let file = event.dataTransfer.files[0];
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = (e) => {
                file.src = e.target.result
                this.profile_picture = file
            }
        },
        openUploadDialog(){
            this.$refs.uploadAvatarInput.click();
        }
    }
}
</script>
<style scoped lang="scss">
.suimodal{
  padding: 54px 44px;
  .icon.close {
    top: 44px;
    right: 44px;
    color: #fff;
  }
  .ui.deny.button{
    background-color: transparent;
    color: #fff;
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 700;
    margin-right: 12px;
  }
  .grouped.fields{
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ui.radio.checkbox{
      border: 1px solid rgba(255, 255, 255, 1);
      border-radius: 8px;
      padding: 12px 24px;
      cursor: pointer;
      label{
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        display: flex;
        align-items: center;
        i{
          font-size: 20px;
          margin-right: 12px;
        }
        &::before{
          top: 5px;
        }
        &::after{
          top: 7px;
          right: 2px;
          background-color: var(--buttons_color);
          color: var(--buttons_color);
          width: 11px;
          height: 11px;
        }
      }
    }
  }
  .ui.image{
    margin-top: 24px;
    img{
      width: 150px;
      height: 150px;
      -o-object-fit: cover;
      object-fit: cover;
      -o-object-position: center;
      object-position: center;
      border-radius: 50%;
    }
    .edit-profile{
      position: absolute;
      right: -5px;
      z-index: 1;
      top: 12px;
      width: 36px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
      border: none;
      background: var(--buttons_color);
      .material-icons{
        font-size: 20px;
        color: var(--fonts_color);
      }
    }
  }
  .upload-area {
    border-radius: 24px;
    border: 2px dashed rgba(255, 255, 255, 0.6);
    margin-top: 24px;
    padding: 48px;
    text-align: center;
    cursor: pointer;
    &.dragging{
      border-color: var(--buttons_color);
    }
  }
  .ui.positive.button{
    background-color: var(--buttons_color);
    color: var(--fonts_color);
    border: none;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 700;
    &.red {
      background-color: #db2828;
      color: #fff;
    }
  }
}
@media only screen and (max-width: 767.98px) {
  .ui.overlay.fullscreen.suimodal > .content.content.content {
    min-height: calc(100vh - 20rem);
  }
}
</style>